import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { first, filter, map, flatMap, tap, switchMap, switchAll, take, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ShopSearchService } from '../../core/services/shop-search.service';
import { Shop } from '../../core/models/shop.model';
import { List } from '../../core/models/list.model';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-search-by-name-modal',
  templateUrl: './search-by-name-modal.component.html',
  styleUrls: ['./search-by-name-modal.component.scss'],
})
export class SearchByNameModalComponent implements OnInit {

  searchFilter$ = new Subject<string>();
  shopList$: Observable<any>;
  loading = false;

  constructor(
    private modalCtrl: ModalController,
    private shopSearch: ShopSearchService,
    private router: Router,
  ) {

    this.shopList$ = this.searchFilter$.pipe(
      tap ( result => this.loading = true ),
      debounceTime(250),
      distinctUntilChanged(),
      flatMap( searchString =>  {
        return this.shopSearch.searchByKeywords( searchString );
      }),
      tap( result => this.loading = false )
    );

  }


  ngOnInit() {}

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  /*onSelected( place: Place | null ) {
    this.modalCtrl.dismiss( place, 'picked' );
  }*/

  onSelected( shop: Shop | null ) {
    this.router.navigate( [ '/restavracija', shop.providerId, shop.slug ] ).then( result => this.modalCtrl.dismiss( shop, 'picked' ) );
  }


  getAvatarUrl(file: string): string {
    return environment.docsUrl + '/' + file;
  }

}
