<div style="background-color: black; padding-bottom: 50px;">
  <ion-grid fixed class="ion-no-padding">
    <div class="secondary-menu ion-padding">
      <div class="secondary-menu__main">
        <a [routerLink]="['/info', 'pomoc']">{{ 'FOOTER.HELP' | translate }}</a>
        <a [routerLink]="['/info', 'za-ponudnike']">{{ 'FOOTER.FOR_OWNERS' | translate }}</a>
        <a [routerLink]="['/info', 'kontakt']">{{ 'FOOTER.CONTACT' | translate }}</a>
      </div>

      <!--div class="secondary-menu__social">
        <a href="https://www.facebook.com/book123app">
          <ion-icon class="facebook"></ion-icon>
        </a>
        <a href="https://www.instagram.com/book123app/">
          <ion-icon class="instagram"></ion-icon>
        </a>
        <a> 
          <ion-icon class="twitter"></ion-icon>
        </a>
      </div-->

      <div class="secondary-menu__legal">
        <p>
          <a [routerLink]="['/info', 'zasebnost']">{{ 'FOOTER.PRIVACY' | translate }}</a>
        </p>
        <p>
          <a [routerLink]="['/info', 'splosni-pogoji']">{{ 'FOOTER.TERMS' | translate }}</a>
        </p>
      </div>

        <p class="secondary-menu__copyrights">&copy; 2023 <a style="color:#707070; text-decoration: none;" href="https://table42.app">TABLE42 d.o.o.</a> {{ 'FOOTER.RIGHTS' | translate }} 0.9.2</p>
    </div>
  </ion-grid>
</div>

<!--div style="background-color: white;" class="ion-padding-top ion-padding-bottom">
  <ion-grid fixed class="ion-no-padding">
  <img src="/assets/images/sps.png" style="margin-left:12px; height:50px;margin-right:20px;" /><img src="/assets/images/eu.svg" style="padding-top:16px; height:68px;" />
  <p style="font-size: xx-small;padding:12px;">Razvoj platforme Price Pilot podpirata Republika Slovenija in Evropska unija</p>
  </ion-grid>
</div-->

<!--<div class="secondary-menu ion-padding">
  <div class="secondary-menu__main">
    <a>Pomoč in podpora</a>>
    <a>Za ponudnike</a>
    <a>Kontakt</a>
  </div>

  <div class="secondary-menu__social">
    <a>
      <ion-icon class="facebook"></ion-icon>
    </a>
    <a>
      <ion-icon class="instagram"></ion-icon>
    </a>
    <a>
      <ion-icon class="twitter"></ion-icon>
    </a>
  </div>

  <div class="secondary-menu__legal">
    <p>
      <a>Politika zasebnosti</a>
    </p>
    <p>
      <a>Splošni pogoji</a>
    </p>
  </div>

  <p class="secondary-menu__copyrights">&copy; 2019 Book123 Inc. All rights reserved</p>
</div>-->