import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'ppImage'
})
export class ImagePipe implements PipeTransform {

  transform( file: string, ...args: any[] ): any {
    
    if (!file) {
      return environment.assetsUrl + '/img/employees/blank.jpg';
    }

    return environment.docsUrl + '/' + file;
  }

}
