import { Attendee } from "./attendee.model"
import { Combination } from "./combination.model"
import { Product } from "./product.model"
import { environment } from '../../../../environments/environment';

export class Pick {

  public shopId: number;
  public customerNotes: string;
  public attendees: Attendee[];
  public products: Product[] = [];
  public partySize: number = 2;
  public combination: Combination;
  public depsIssue: boolean = false;
  public total = 0.00;
  public payed = false;

  constructor(
    obj?: any 
  ) {
    this.shopId = obj && obj.shopId || null;
    this.customerNotes = obj && obj.customerNotes || null;
    this.partySize = obj && obj.partySize || 2;
    this.attendees = obj && obj.attendees || [];
    this.combination = obj && obj.combination || null;
  }

  setCustomerNotes( cn: string ) {
    this.customerNotes = cn;
  }

  areServicesPicked() {
    
    let a = this.getAttendeeByIndex( 0 );
    if ( !a.services || a.services.length === 0 ) {
      return false;
    }
    return true;
  }

  areServicesPickedAndDepsOk() {

    let someAttendeesOk = false;

    for( let a of this.attendees ) {
      
      if ( a.areServicesPicked() ) {

        if (a.depsIssue ) {
          return false;

        } else {
          someAttendeesOk = true;
        }

      }

    }

    return someAttendeesOk;

  }

  isCombinationPicked() {
    return !!this.combination;
  }

  getCompletedStepIndex() {

    let completedStepIndex: number = -1;

    if ( !this.attendees || !this.attendees[0] || !this.attendees[0].services ) {
      return -1;
    }
    
    if ( this.attendees[0].services.length > 0 ) {
      completedStepIndex = 0;
    }

    if ( this.combination && 0 === completedStepIndex ) {
      completedStepIndex = 1;
    }
    
    return completedStepIndex;

  }

  setCombination( combination: Combination ) {
    this.combination = combination;
  }

  unsetCombination() {
    this.combination = null;
  }

  emptyProducts() {
    this.products = [];
  }

  addAttendee( attendee ) {
    this.attendees.push( attendee );
  }

  getAttendeeByIndex( index:number ): Attendee {
    
    if ( !!this.attendees[index] ) {
      return this.attendees[index];
    } else {
      this.attendees[index] = new Attendee();
      return this.attendees[index];
    }

  }

  private getProviderHrefFromServiceHref( serviceHref ) {
    return serviceHref.substring( 0, serviceHref.indexOf('/services') );
  }

  private getProviderHrefFromSelectionItemHref( selectionItemHref ) {
    return selectionItemHref.substring( 0, selectionItemHref.indexOf('/services') );
  }


  //PRODUCTS
  areProductsPickedAndCombinationSet() {

     console.log( 'fooooooooooooo');
     console.log( this.isCombinationPicked() );
     console.log( this.areProductsPicked() );
      console.log( this.getMenusPickedCount() );

    if ( this.isCombinationPicked() && this.areProductsPicked() && this.getMenusPickedCount() === this.partySize ) {



      return true;
    }

    return false;
    
  }

  areTooManyProductsPicked() {

    if ( this.areProductsPicked() && this.getMenusPickedCount() > this.partySize ) {
      return true;
    }

    return false;
    
  }


  getProductQtyById( productId ) {

    for( let i=0; i < this.products.length; i++ ) {

      if ( this.products[i].id === productId ) {
        return this.products[i].quantity;
      }

    }

    return 0;

  }

  getProductCategoriesPicked(): Array<number> {
    const ids = this.products.map( (value, index, array) => {
      return value.parentId;
    });

    const uniqueIds = [...new Set(ids)];
    return uniqueIds;
  }

  setDepsIssue( hasIssue: boolean ) {
    this.depsIssue = hasIssue;
  }

  areProductsPicked() {
    return ( this.products.length > 0 );
  }


  getMenusPickedCount() {

    let count = 0;

    for( let i=0; i < this.products.length; i++ ) {
      count += this.products[i].quantity;
    }

    return count;

  }

  pickMaxProductsFilter( parentId, pickMax ) {

    if ( !pickMax || pickMax < 1 ) {
      return;
    }

    let categoryIndexArray: Array<number> = [];

    for( let i=0; i < this.products.length; i++ ) {
      
      if ( parentId === this.products[i].parentId ) {
        categoryIndexArray.push( i );
      }

      const diff = categoryIndexArray.length - pickMax

      if ( diff > 0 ) {
        this.products.splice( categoryIndexArray[0], diff )
      }

    }

  }

  calculateTotal() {

    let total = 0.00;

    for( let i=0; i < this.products.length; i++ ) {

      total += ( this.products[i].price.amount * this.products[i].quantity );

    }

    this.total = total;
    return this.total;

  }

  toggleProduct( serviceData, categoryData, qty ) {


    for( let i=0; i < this.products.length; i++ ) {
      if ( this.products[i].id == serviceData.id ) {
        this.products.splice(i, 1);
      }
    }

    if ( qty === 0 ) {
      this.calculateTotal();
      return true;
    }

    //@todo for now this is ok, but in the future we need to introduce dynamic pricing and discounts, by using regular prices and discounts for picked combination
    let getPriceFromCombinationAndProduct = function( combination, serviceData ) {
      return serviceData.minimalPrice.lowestPrice;
    }
    
    const product = new Product({
      'id': serviceData.id,
      'quantity': qty,
      'url': serviceData._links.self.href,
      'name': serviceData.name,
      'duration': serviceData.duration,
      'price': getPriceFromCombinationAndProduct( this.combination, serviceData), //fixed prices only!!!, update ASAP
      'parentId': categoryData.id
    });

    if ( categoryData.dependencies && categoryData.dependencies.fullfilledBy && categoryData.dependencies.fullfilledBy[0] ) {
      product.parentDependencies = categoryData.dependencies;
    }

    if ( serviceData.dependencies && serviceData.dependencies.fullfilledBy && serviceData.dependencies.fullfilledBy[0] ) {
      product.dependencies = serviceData.dependencies;
    }
    
    this.products.push(product);

    this.pickMaxProductsFilter( categoryData.id, categoryData.childrenPickMax ? categoryData.childrenPickMax: 0 );
    
    console.log(this.products );
    this.calculateTotal();

    return product;

  }


  //PRODUCTS END
  get bookingPostObject() {

    const bookingObj = {   
      "start": this.combination.start,
      //"provider": this.getProviderHrefFromServiceHref( this.products[0].url ),
      "provider": this.getProviderHrefFromSelectionItemHref( this.combination.selectionItems[0].locationHref ),
      "partySize": this.partySize,
      'customerNotes': this.customerNotes,  
      'site': environment.apiUrl + '/sites/' + environment.siteId,    
      'mode': 20,    
      //"provider": this.getProviderHrefFromServiceHref( this.combination.attendees[0].services[0].serviceHref )
    };

    bookingObj['bookingSelectionItemsData'] = [];

    for( let selectionItem of this.combination.selectionItems ) {

      bookingObj['bookingSelectionItemsData'].push({
        'selectionItem': selectionItem.locationHref,
        'start': selectionItem.start,
        'end': selectionItem.end
      });

    }

    bookingObj['bookingProductsData'] = [];


    for( let product of this.products ) { //fixed prices only!!! Think about moving price calculation to combination products...

      bookingObj['bookingProductsData'].push({
        'service': product.url,
        'price': product.price.amount,
        'currency': 'EUR',
        'quantity': product.quantity,
      });

    }

    return bookingObj;

  }

}