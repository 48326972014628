import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Real } from '../../core/models/real.model';

@Component({
  selector: 'app-offer-info-modal',
  templateUrl: './offer-info-modal.component.html',
  styleUrls: ['./offer-info-modal.component.scss'],
})
export class OfferInfoModalComponent implements OnInit {

  @Input() service: any;
  @Input() real: Real;

  constructor(private router: Router, private modalCtrl: ModalController, public translateService: TranslateService) { }

  ngOnInit() {

  }

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  goToPickSlot( real ) {

    let queryParamsObj = {};
    
    if ( 19494 == this.service.id || 19500 == this.service.id || 18144 == this.service.id  ) {
      queryParamsObj = { from: '2023-03-24' }
    }

    this.onCancel();
    this.router.navigate(['/', 'restavracija', real.provider.id, real.slug, 'rezervacija', 'izberi-termin'], { queryParams: queryParamsObj } );
  }

}
