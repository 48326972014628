import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ppTranslate'
})
export class PpTranslatePipe implements PipeTransform {

  constructor( public translate: TranslateService )  {
  }

  transform( defaultText: string, translations: any, currentLanguage = 'sl'): string {

     /*console.log('baaaaaaaaaaaaaaaaaaaaaaar'); 
     console.log( defaultText );
     console.log(this.translate.currentLang);
     console.log(translations);*/

    var languageCode = this.translate.currentLang !== undefined ? this.translate.currentLang.split('-')[0] : undefined;

    //console.log(languageCode);  

    if(
        languageCode === undefined
        || translations === null 
        || typeof translations !== 'object' 
        || translations[languageCode] === undefined) {

          //default to english for dynamic content
          if ( languageCode !== 'sl' && translations[languageCode] === undefined && translations['en'] !== undefined) {
            return translations['en'];
          }
          
          return defaultText;
          

    } else {
        return translations[languageCode];
    }

  }

}
