import { Dependencies } from "./dependencies.model"
import { Price } from "./price.model"

 export class Product {
  public id: number;
  public quantity: number;
  public url: string;
  public name: string;
  public duration: number;
  public parentId: number;
  public dependencies: Dependencies;
  public parent: Product;
  public parentDependencies: Dependencies;

  public price: Price;


  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.quantity = obj && obj.quantity || null;
    this.url = obj && obj.url || null;
    this.name = obj && obj.name || null;
    this.duration = obj && obj.duration || null;
    this.parentId = obj && obj.parentId || null;
    this.dependencies = obj && obj.dependencies || null;
    this.parentDependencies = obj && obj.parentDependencies || null;
    this.parent = obj && obj.parent || null;

    if ( obj && obj.price ) {
      this.price = new Price( obj.price, obj.currency ? obj.currency : 'EUR' );
    }

  }
}
