import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { ParamMap } from '@angular/router';

import { Observable, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';

import { Place } from '../models/place.model';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  constructor( private http: HttpClient ) {
  }  

  getBySlug( slug: string ) {

    return this.getPlaces( null, slug ).pipe(
      map( result => result[0] )
    );
  }

  getBySearchString( searchString: string ) {
    return this.getPlaces( searchString );
  }

  getPlaces( searchString, slug = null ) {
    
    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.place ) {
        return response._embedded.place.map(
          placeApiData => new Place( placeApiData )
        )

      } else {
        return [];
      }

    } 

    const apiUrl = environment.apiUrl;
    const endpoint = 'places';

    let minPopulation: number;
    let query: string[] = [];

    if ( searchString ) {
      
      query.push('searchString=' + searchString);


      switch( searchString.length ) {

        case 0:
          minPopulation = 300;
          break;

        case 1:
          minPopulation = 100;
          break;

        case 2:
          minPopulation = 100;
          break;

        default:
          minPopulation = 100;

      }

      query.push('minPopulation=' + minPopulation);

    }

    if ( slug ) {
      query.push('slug=' + slug);
    }

    query.push('pageSize=100');

    const url = apiUrl + endpoint + '?' + query.join('&');

    return this.http.get( url ).pipe(
       map ( response => responseToModel( response ) )
    );

  }


}