export class Image   {
  
  public id: string;
  public type: string;
  public fileName: string;
  public title: string;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.type = obj && obj.document && obj.document.type || null;
    this.fileName = obj && obj.document && obj.document.fileName || null;
    this.title = obj  && obj.document && obj.document.title || null;
  }
  
}