<ion-content style="border-top:4px solid #0100ee;" min="0" max="2400" color="white" class="ion-padding profile">
  <!--div class="ion-padding profile__picture">
    img [src]="service?.avatar?.document?.fileName | ppImage">
  </div-->

  <ion-button fill="clear" style="margin-right:0px;" class="ion-float-right" (click)="onCancel()">
    <ion-icon name="close" mode="md"></ion-icon>
  </ion-button>

  <div class="ion-padding profile__info" style="padding-bottom:0px; ">

    <!--h2 *ngIf="service.descriptionButtonText"><ion-icon style="color:black;" name="calendar-outline"></ion-icon> {{ service.descriptionButtonText | ppTranslate:service.descriptionButtonTextTranslations:translateService.currentLang }}</h2-->
    <div style="display: table; margin-top:5px;">
    <div style="display: table-row">
        <div style="width: 50px; display: table-cell; vertical-align: middle;"> <ion-img [src]="'/assets/icons/event.svg'"></ion-img> </div>
        <div style="padding-left:10px">
          <p style="font-size:small !important; color: #0100ee; font-weight: bold; line-height: 0.8 !important; color: #0100ee;">{{ service?.shortName | ppTranslate:service.shortNameTranslations:translateService.currentLang }}</p>
          <p style="font-size:small !important; line-height: 0.8 !important;">{{ service?.descriptionButtonText | ppTranslate:service.descriptionButtonTextTranslations:translateService.currentLang }}</p>
        </div>
    </div>
    </div>

    <h1 style="margin-top:15px;">{{ service.name | ppTranslate:service.nameTranslations:translateService.currentLang }}</h1>

    <!--h2>{{ service.shortDescription | ppTranslate:service.longDescriptionTranslations:translateService.currentLang }}</h2-->
    <p *ngIf="service.longDescription" class="formatted" innerHtml="{{ service.longDescription | ppTranslate:service.longDescriptionTranslations:translateService.currentLang }}"></p>

  </div>

  <ng-container *ngFor="let service of service?.children">
        <ion-item  *ngIf="service.visibleForCustomers"> <!-- class="clickable" (click)="toggleService( service, category )"-->
            <ion-grid  class="ion-no-padding">
              <ion-row class="ion-no-padding ion-align-items-start services-list-row">
                <!--ion-col size="1">
                  &bull;
                  ion-checkbox name="cb-2" mode="md" [checked]="isServicePicked(service)"></ion-checkbox
                </ion-col-->
                <ion-col size="8" class="services-list-row__item-name">{{ service.name | ppTranslate:service.nameTranslations:translateService.currentLang }}</ion-col>
                <ion-col size="4" class="ion-text-right services-list-row__item-price">
                  

                  <span class="services-list-row__item-discount"></span>
                  <span *ngIf="service.minimalPrice.varyPrices">{{ 'EVENT_CARD.FROM' | translate }} </span><span *ngIf="service.minimalPrice.lowestPrice">{{ service.minimalPrice.lowestPrice | currency: 'EUR' }}</span>
                  
                </ion-col>


                <!--ion-col size="1"></ion-col-->
                <ion-col *ngIf="false" size="8" class="services-list-row__item-time">
                {{  ( service.duration / 60 ) | number:'1.0-0' }} min
                </ion-col>
                <ion-col *ngIf="false" size="3" class="ion-text-right services-list-row__list-icon">
                  <ion-icon name="list" mode="ios"></ion-icon>
                </ion-col>
              </ion-row>
              <!-- && isServicePicked(service) -->
              <ion-row *ngIf="service.longDescription" class="services-list-row__item-expanded-info ion-align-items-start">
                <!--ion-col size="1"></ion-col-->
                <ion-col size-xs="3" size-md="2" *ngIf="service.avatar?.document?.fileName">
                  <img class="ion-float-left" style="width: 100%; padding: 0px 16px 0px 0px"  [src]="service.avatar?.document?.fileName | ppImage" />
                </ion-col>
                <ion-col size-xs="9" size-md="10">
                  <p class="formatted" innerHtml="{{ service.longDescription | ppTranslate:service.longDescriptionTranslations:translateService.currentLang }}"></p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
      </ng-container>
  
  <div class="ion-float-left clickable" style="margin-top:36px;">
    <a (click)="onCancel()"  class="pp-link">{{ 'EMPLOYEE_MODAL.CLOSE' | translate }}</a>
  </div>

  <div class="ion-float-right clickable" style="margin-top:20px;">
    <ion-button *ngIf="real" style="font-size: 13px;" class="ion-float-right" color="primary" 

    (click)="goToPickSlot(real);" class="btn--large ion-no-margin">{{ 'SHOP_DETAIL.BTN_BOOK' | translate }}&nbsp;<ion-icon style="color: white" name="calendar-outline"></ion-icon></ion-button>
  </div>

  

</ion-content>