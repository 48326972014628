export class ProviderUser {

  public id: string;
  public promotionsViaEmail: boolean
  public promotionsViaSms: boolean

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.promotionsViaEmail = obj && obj.promotionsViaEmail || false;
    this.promotionsViaSms = obj && obj.promotionsViaSms || false;
  }

}