import { Injectable } from '@angular/core';
import { SearchPreferences } from '../models/search-preferences/search-preferences.model';
import { Place } from '../models/search-preferences/place.model';
import { Radius } from '../models/search-preferences/radius.model';
import { DatePeriod, DatePeriodPickedOption } from '../models/search-preferences/date-period.model';
import { HoursPeriod } from '../models/search-preferences/hours-period.model';
import { Sort } from '../models/search-preferences/sort.model';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, tap, concatMap, concat, first   } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ParamMap } from '@angular/router';
import * as moment from 'moment';

import { NormalizedServicesService } from './normalized-services.service';
import { PlacesService } from './places.service';

import { ServicePickModalComponent } from '../../shared/service-pick-modal/service-pick-modal.component';
import { PlacePickModalComponent } from '../../shared/place-pick-modal/place-pick-modal.component';
import { DatePickModalComponent } from '../../shared/date-pick-modal/date-pick-modal.component';
import { HoursPickModalComponent } from '../../shared/hours-pick-modal/hours-pick-modal.component';
import { environment } from '../../../environments/environment';

import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SearchPreferencesService {

  private searchPreferences:Subject<SearchPreferences> = new BehaviorSubject<SearchPreferences>( new SearchPreferences() );
  searchPreferences$ = this.searchPreferences.asObservable();
  shopListingUrlParams$:Observable<Array<string>>;

  addSearchPreferences( searchPreferences: SearchPreferences ) {
    this.searchPreferences.next( searchPreferences );
  }

  updatePreference( 
    fieldName: 'normalizedService' | 'place' | 'datePeriod' | 'hoursPeriod' | 'sort' | 'radius' | 'freeParking',
    newValue: any
  ) {

    console.log('updated preference');
    console.log(fieldName, newValue);

    this.searchPreferences$.pipe(first()).subscribe( oldSearchPreferences => {
      const updatedSearchPreferences = new SearchPreferences( oldSearchPreferences );
      updatedSearchPreferences[ fieldName ] = newValue;
      if ( 'place' === fieldName ) {
        updatedSearchPreferences[ 'radius' ] = null;
      }

      //do not update if value is the same
      if ( updatedSearchPreferences[ fieldName ] === oldSearchPreferences [ fieldName ] ) {
        console.log('Do not update - same value!');
        return false;
      }

      this.addSearchPreferences( updatedSearchPreferences );
    })

  }

  constructor( 
    private router: Router,
    private modalCtrl: ModalController,
    private normalizedServicesService: NormalizedServicesService,
    private placesService: PlacesService,
  ) { 
  }

  navigateToShopListing() {

    this.searchPreferences$.pipe(
      first(), //no need to unsubscribe
      tap(
        searchPreferences => {
          this.router.navigate( 
            this.composeRouteArray( searchPreferences ), 
            { queryParams: this.composeQueryParamsObj( searchPreferences ) } 
          );
        } 
      )
    ).subscribe();

  }

  async initSearchPreferences( params: ParamMap, queryParams: ParamMap ) {

    console.log('initSearchPreferences');

    let constructorParams = {};

    let serviceSlug: string;

    //normalized service
    if ( params.has('service') ) {
      //constructorParams['normalizedService'] = this.normalizedServicesService.getBySlug( params.get('service') );
      serviceSlug = params.get('service');
    } else if ( params.has('serviceType') ) {
      //constructorParams['normalizedService']= this.normalizedServicesService.getBySlug( params.get('serviceType') );
      serviceSlug = params.get('serviceType');
    }

    if ( serviceSlug ) {
      console.log('request for normalized service');
      constructorParams['normalizedService'] = await this.normalizedServicesService.getBySlug( serviceSlug ).toPromise();
      console.log(constructorParams['normalizedService']);
    }
    


    if ( params.has('location') ) {

      constructorParams['place'] = await this.placesService.getBySlug(  params.get('location') ).toPromise();

      //set defaults knowning the api rules
      //it would be better to set default from shops api request
      /*let radius;
      
      if (constructorParams['place'] && constructorParams['place'].population ) {

        if ( constructorParams['place'].population > 100000 ) {
          radius = 10;
        } else if ( constructorParams['place'].population > 10000 ) {
          radius = 20;
        } else {
          radius = 25;
        }

        constructorParams['radius'] = new Radius(radius);

      }*/

    }


    //radius
    if ( queryParams.has('radius') ) {
      constructorParams['radius'] = new Radius( 
        +queryParams.get('radius')
      );
    } 


    //date
    if ( queryParams.has('date_from') && queryParams.has('date_to') ) {
      constructorParams['datePeriod'] = new DatePeriod( 
        DatePeriodPickedOption.Custom, 
        moment(queryParams.get('date_from')), 
        moment(queryParams.get('date_to'))
      );
    } 

    //hours
    const getHourFromTime = ( timeStr: string ): number => {
      return +timeStr.substring( 0, timeStr.indexOf(':') );
    }

    if ( queryParams.has('time_from') && queryParams.has('time_to') ) {
      constructorParams['hoursPeriod'] = new HoursPeriod( 
        getHourFromTime( queryParams.get('time_from') ), 
        getHourFromTime( queryParams.get('time_to') )
      );
    }

    //free parking
    if ( queryParams.has('free_parking') ) {
      constructorParams['freeParking'] = true;
    } 

    //sort
    if ( queryParams.has('sort') ) {
      const sortArr = <any>queryParams.get('sort').split('_');
      constructorParams['sort'] = new Sort( sortArr[0], sortArr[1] );
    } 

    console.log('add search preferences...');
    this.addSearchPreferences( new SearchPreferences( constructorParams ) );

  }

  composeRouteArray( searchPreferences: SearchPreferences ) {

    let place = 'vse-lokacije';

    if ( searchPreferences.place ) {
      place = searchPreferences.place.slug
    }

    let routeArr = [ place ];

    //check if picked value 
    if ( searchPreferences.normalizedService ) {

      if ( searchPreferences.normalizedService.urlParentSlug ) { //add parent- frizer for mosko-strizenje
        routeArr.push( searchPreferences.normalizedService.urlParentSlug );
      }

      routeArr.push( searchPreferences.normalizedService.slug ); //add service

    }

    return routeArr;

  }

  composeQueryParamsObj( searchPreferences: SearchPreferences ) {
  
    console.log( searchPreferences );

    let queryParamsObj = {};

    if ( searchPreferences.datePeriod ) {
      queryParamsObj['date_from'] = searchPreferences.datePeriod.fromMoment.format('YYYY-MM-DD');
      queryParamsObj['date_to'] = searchPreferences.datePeriod.toMoment.format('YYYY-MM-DD');
    }

    if ( searchPreferences.hoursPeriod ) {
      const addLeadingZero = ( input: string ): string => ( 1 === input.length ) ? '0' + input: input;
      queryParamsObj['time_from'] = addLeadingZero( searchPreferences.hoursPeriod.fromHour.toString() ) + ':00';
      queryParamsObj['time_to'] = addLeadingZero(  searchPreferences.hoursPeriod.toHour.toString() ) + ':00';
    }

    if ( searchPreferences.sort ) {
      queryParamsObj['sort'] = searchPreferences.sort.param + '_' + searchPreferences.sort.order;
    }

    if ( searchPreferences.radius ) {
      queryParamsObj['radius'] = searchPreferences.radius.maxDistance;
    }

    if ( searchPreferences.freeParking ) {
      queryParamsObj['free_parking'] = searchPreferences.freeParking?1:0;
    }

    console.log(queryParamsObj);

    return queryParamsObj;
  }

  openModal( 
    pickType: 'service' | 'place' | 'date' | 'time', 
    searchPreferences: SearchPreferences
  ) {

    let componentClass;
    let preferenceField: 'normalizedService' | 'place' | 'datePeriod' | 'hoursPeriod';

    switch ( pickType ) {
      case 'service':
        componentClass = ServicePickModalComponent;
        preferenceField = 'normalizedService';
        break;

      case 'place':
        componentClass = PlacePickModalComponent;
        preferenceField = 'place';
        break;

      case 'date':
        componentClass = DatePickModalComponent;
        preferenceField = 'datePeriod';
        break;

      case 'time':
        componentClass = HoursPickModalComponent;
        preferenceField = 'hoursPeriod';
        break;

    }

    this.modalCtrl.create({
      component: componentClass,
      cssClass: 'my-modal',
      componentProps: { searchPreferences: searchPreferences }
    })
    .then( modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    })
    .then( resultData => {

      if ( 'picked' === resultData.role && undefined !== resultData.data  ) {
        console.log('picked', resultData);
        this.updatePreference( preferenceField, resultData.data );
      }

    });

  }

}