import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { NormalizedServicesService } from '../../core/services/normalized-services.service';
import { NormalizedService } from '../../core/models/normalized-service.model';
import { SearchPreferences } from '../../core/models/search-preferences/search-preferences.model';

import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';

@Component({
  selector: 'app-service-pick-modal',
  templateUrl: './service-pick-modal.component.html',
  styleUrls: ['./service-pick-modal.component.scss'],
})
export class ServicePickModalComponent implements OnInit {

  services$: Observable<Array<NormalizedService>>;
  
  @Input() searchPreferences: SearchPreferences;

  searchFilter$ = new BehaviorSubject<string>('');

  constructor(
    private modalCtrl: ModalController,
    private normalizedServicesService: NormalizedServicesService
  ) { 

    //this.services = this.normalizedServicesService.getAllArray();

    this.services$ = this.searchFilter$.pipe(
      flatMap( searchString =>  {
        return this.normalizedServicesService.getBySearchString( searchString );
      })
    )
  }

  ngOnInit() {}

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  onSelected( service: NormalizedService | null ) {
    this.modalCtrl.dismiss( service, 'picked' );
  }

}
