import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { OfferInfoModalComponent } from '../../shared/offer-info-modal/offer-info-modal.component';

import { Real } from '../../core/models/real.model';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {

  @Input() service: any;
  @Input() real: Real;

  constructor( private modalCtrl: ModalController , public translateService: TranslateService ) { }

  ngOnInit() {}

  openOfferInfoModal(service) {

    this.modalCtrl.create({
      component: OfferInfoModalComponent,
      cssClass: 'my-modal',
      componentProps: { 'service': service, 'real': this.real }
    })
    .then( modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    })
    .then( resultData => {

      /*if ( 'picked' === resultData.role && undefined !== resultData.data  ) {
        //redirect to shop
        this.router.navigate( [ '/restavracija', resultData.data.providerId, resultData.data.slug ] );
      }*/

    });
  }

}
