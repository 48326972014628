 <div class="phone-modal">
  <ng-container *ngIf="me$ | async as me">
  
  <ng-container *ngIf="!me.phoneVerified && !!me.phone && me.phone.substr(0,4) =='+386'">
  
  <form class="phone-modal__form form" #codeF (ngSubmit)="onCodeFormSubmit( submitBtn )">

    <div  class="ion-padding">
    <h1 class="phone-modal__form-title">{{ 'PHONE.ENTER_CODE' | translate }}</h1>

    <p class="phone-modal__form-desc">{{ 'PHONE.ENTER_CODE_INSTRUCTIONS' | translate }}</p>

    <ion-list>

      <ion-item class="ion-no-padding" mode="md">
        <ion-label position="stacked" mode="md" class="phone-modal__form-label">{{ 'PHONE.SMS_CONFIRMATION_CODE' | translate }}</ion-label>
        <ion-input class="phone-modal__form-input" name="code" [(ngModel)]="code" type="text" id="code" placeholder="{{ 'PHONE.SMS_CONFIRMATION_CODE_NAMESPACE' | translate }}"></ion-input>
      </ion-item>

     </ion-list>

    <ion-button style="font-size: 13px" class="btn--large" type="submit" expand="full">{{ 'PHONE.BTN_CONFIRM_CODE' | translate }}</ion-button>
    <p class="ion-text-center register-prompt">{{ 'PHONE.NO_CODE' | translate }} <a class="pp-link" (click)="resendCode(); $event.stopPropagation()">{{ 'PHONE.SEND_AGAIN' | translate }}</a></p>

    </div>
  </form>   

  <hr/>   

  </ng-container>     

  <form class="phone-modal__form form" #phoneF="ngForm"  (ngSubmit)="onPhoneFormSubmit( submitBtn )">

    <div  class="ion-padding">
    <h1 class="phone-modal__form-title">{{ 'PHONE.YOUR_PHONE_NUMBER' | translate }}</h1>

    <p class="phone-modal__form-desc">{{ 'PHONE.ENTER_PHONE_NUMBER_INSTRUCTIONS' | translate }}</p>

    <ion-list>

      <ion-item class="phone-input ion-no-padding" mode="md">
        <ion-label mode="md" class="phone-modal__form-label" position="stacked" position="stacked">{{ 'PHONE.PHONE_NUMBER' | translate }}</ion-label>
        <ion-intl-tel-input
            id="phone-number" 
            name="phone-number"
            defaultCountryiso="si"
            inputPlaceholder="{{ 'PHONE.PHONE_INPUT_PLACEHOLDER' | translate }}"
            [(ngModel)]="phone2"
            [preferredCountries]="['si', 'at', 'de',  'hr', 'it', 'se', 'ch', 'gb', 'us', 'ua', 'fr']"
            #phoneNumberControl="ngModel" 
            >

          </ion-intl-tel-input>
      </ion-item>

      <!--ion-item class="ion-no-padding" mode="md">
        <ion-label mode="md" class="phone-modal__form-label" position="stacked" position="stacked">Telefonska številka</ion-label>
        <ion-input name="phone" class="phone-modal__form-input" [ngModel]="formatter(phone)" (ngModelChange)="phone = parser($event)" type="text" id="phone" placeholder="npr. 031 xxx xxx"></ion-input>
      </ion-item-->

     </ion-list>

    <ion-button style="font-size: 13px" class="btn--large" [disabled]="phoneF.invalid || !isPhoneValid() || isEqual()" type="submit" #submitBtn expand="full"> {{ 'PHONE.BTN_SAVE' | translate }} {{ (( phone2?.dialCode == '+386' ) ? 'PHONE.BTN_AND_SEND_CODE' :'') | translate }}</ion-button>
    <p class="ion-text-center register-prompt">{{ 'PHONE.HELP' | translate }}</p>
    </div>
  </form>

</ng-container>
</div>

