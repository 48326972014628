

<ion-grid (click)="openOfferInfoModal(service); $event.stopPropagation();" style="padding:0px; margin:16px; border: 1px solid #dedede" class="clickable">
  <ion-row>


   <ion-col size-xs="12" size-md="8" size-xl="7" style="padding:0 20px 0 20px;" >


    <div style="width: 100%; display: table; margin-top:5px;">
    <div style="display: table-row">
        <div style="width: 50px; display: table-cell; vertical-align: middle;"> <ion-img [src]="'/assets/icons/event.svg'"></ion-img> </div>
        <div style="padding-left:10px">
          <p style="font-size:smaller; color: #0100ee; font-weight: bold; line-height: 0.8; color: #0100ee;">{{ service?.shortName | ppTranslate:service.shortNameTranslations:translateService.currentLang }}</p>
          <p style="font-size:smaller; line-height: 0.8;">{{ service?.descriptionButtonText | ppTranslate:service.descriptionButtonTextTranslations:translateService.currentLang }}</p>
        </div>
    </div>
    </div>
      <!--div style="width: 100%; display: table;">
        <div style="display: table-row; padding:0px; margin: auto;">
            <div style="display: table-cell; width:40px; "><ion-img style="width: 100%;" [src]="'/assets/icons/event.svg'"></ion-img></div>
            <div style="display: table-cell;"> Right </div>
        </div>
      </div-->

      <!--ion-grid style="padding:0px; margin-top:5px;">
        <ion-row>
          
          <ion-col style="padding:0px;">
            <ion-img style="width: 40px;" [src]="'/assets/icons/event.svg'"></ion-img>
          </ion-col>


          <ion-col>
            <p style="font-size:smaller;">12. do 15. februar 2023</p>
            <p style="font-size:smaller;">med 16:00 in 22:00 uro</p>
          </ion-col> 

        </ion-row>
      </ion-grid-->  

    <div style="border-bottom:1px solid #efefef; padding-bottom:5px;">
      <h6 style="font-weight: bold;">{{ service?.name | ppTranslate:service.nameTranslations:translateService.currentLang }}</h6>
      <p style="color:#494f56; font-size:smaller; line-height: 1.5em; height: 3em; overflow: hidden;">{{ service?.shortDescription | ppTranslate:service.shortDescriptionTranslations:translateService.currentLang }}</p>

      <ion-grid class="ion-no-padding">
        <ion-row >
          <ion-col size="7">
            <a style="font-size:smaller; text-decoration: none; color: #0100ee;">{{ 'EVENT_CARD.MORE' | translate }}</a>
          </ion-col>
          <ion-col size="5">
            <span *ngIf="service?.minimalPrice?.lowestPrice" style="color:#0100ee; font-weight:bold;" class="ion-float-right"><span *ngIf="service.minimalPrice.highestPrice!=service.minimalPrice.lowestPrice">{{ 'EVENT_CARD.FROM' | translate }} </span>{{ service.minimalPrice.lowestPrice | currency: 'EUR': 'symbol': '1.0-2' }}</span>
          </ion-col> 
        </ion-row>
      </ion-grid>  

      


    </div>

    <div style="color:#211e1e; font-size:x-small; margin-top:3px; margin-bottom:7px;">
      {{ service.color }}
    </div>


    </ion-col>


    <ion-col size-xs="0" size-md="4" size-lg="0" size-xl="5">
      <ion-img style="width: 100%;" [src]="service.avatar?.document?.fileName | ppImage"></ion-img>
    </ion-col> 

  </ion-row>
</ion-grid>  






<!--ion-card (click)="openOfferInfoModal(service); $event.stopPropagation();" class="advertisment--saloon clickable" button="false" mode="ios">
  <div class="sticky-discount sticky-discount" style="background-color: #0100ee;"><ion-icon style="font-size:150%;" color="primary-contrast" name="calendar-outline"></ion-icon></div>
  <ion-img class="advertisment--saloon__img" [src]="'https://www.99.co/singapore/insider/wp-content/uploads/2017/02/valentine-day-dinner-ideas.jpg'"></ion-img>

  <ion-card-header   class="ion-no-padding">
    <ion-card-title class="advertisment--saloon__title">{{ service?.descriptionButtonText | ppTranslate:service.descriptionButtonTextTranslations:translateService.currentLang }}&nbsp;</ion-card-title>

    <ion-card-subtitle class="advertisment--saloon__subtitle">{{ service?.name }}</ion-card-subtitle>
    
    <ion-card-content class="advertisment--saloon__content">
      {{ service?.shortDescription | ppTranslate:service.shortDescriptionTranslations:translateService.currentLang }}&nbsp;
    </ion-card-content>

    <p>
      <a class="pp-link">VEČ O DOGODKU</a>
    </p>
  </ion-card-header>
</ion-card-->