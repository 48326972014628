<ion-header class="ion-no-padding header header-modal -primary" mode="md">
  <ion-toolbar class="ion-no-padding" mode="md" color="primary">

    <ion-title slot="start" style="padding-left:10px"><img src="../../assets/logo_table42.svg"></ion-title>
    <!--ion-buttons slot="start">
      <ion-select interface="action-sheet" [selectedText]="translateService.currentLang.toUpperCase()" style="padding-left:15px; max-width:100%; font-size: small;" [ngModel]="translateService.currentLang" (ionChange)="onChange($event)">
      <ion-select-option value="sl">SL - Slovenščina</ion-select-option>
      <ion-select-option value="en">EN - English</ion-select-option>
      <ion-select-option value="de">DE - Deutsch</ion-select-option>
      <ion-select-option value="it">IT - Italiano</ion-select-option>
    </ion-select>

    </ion-buttons-->

    <ion-buttons slot="end">
      <ion-menu-toggle>
        <ion-button><ion-icon style="color:white" size="medium" name="close-sharp"></ion-icon></ion-button>
      </ion-menu-toggle>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-list>

  <!--<ion-item>
    <ion-label>Moje rezervacije</ion-label>
  </ion-item>

  <ion-item>
    <ion-label>Nastavitve</ion-label>
  </ion-item>

  -->
  <!--<ion-item disabled="true">  
    <ion-label>Jezik</ion-label>
    <ion-select>

      <ion-select-option value="sl" selected>Slovenščina</ion-select-option>
      <ion-select-option value="en">English</ion-select-option>
      <ion-select-option value="hr">Hrvatski</ion-select-option>
      <ion-select-option value="sr">Srbski</ion-select-option>
    </ion-select>
  </ion-item>-->

  <ion-menu-toggle>
    <ion-item [routerLink]="['/info', 'za-ponudnike']">
      <ion-icon style="color:black; font-size: 20px;" slot="end" name="restaurant" button></ion-icon>
      <ion-label>{{ 'MENU.FOR_OWNERS' | translate }}</ion-label>
    </ion-item>
  </ion-menu-toggle>

  <ng-container *ngIf="me$ | async as me; else loggedOut">
    <ion-menu-toggle>
      <ion-item [routerLink]="['/moj-profil', 'moje-rezervacije']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="calendar" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.MY_BOOKINGS' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item [routerLink]="['/moj-profil']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="person" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.MY_PROFILE' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item (click)="logout()">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="power" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.LOGOUT' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ng-container>

  <ng-template #loggedOut>
    <ion-menu-toggle>
      <ion-item [routerLink]="['/auth', 'prijava']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="person"></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.LOGIN' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ng-template>
  

</ion-list>