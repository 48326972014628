import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePipe } from './image.pipe';
import { PhoneModalComponent } from './phone-modal/phone-modal.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { PhoneFormComponent } from './phone-modal/phone-form/phone-form.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';
import { TopRightComponent } from './top-right/top-right.component';
import { ServicePickModalComponent } from './service-pick-modal/service-pick-modal.component';
import { PlacePickModalComponent } from './place-pick-modal/place-pick-modal.component';
import { DatePickModalComponent } from './date-pick-modal/date-pick-modal.component';
import { HoursPickModalComponent } from './hours-pick-modal/hours-pick-modal.component';
import { SearchByNameModalComponent } from './search-by-name-modal/search-by-name-modal.component';
import { OfferInfoModalComponent } from './offer-info-modal/offer-info-modal.component';
import { EventCardComponent } from './event-card/event-card.component';
import { AngularPhoneUtilsLibModule } from 'angular-phone-utils-lib';
import { RouterModule } from '@angular/router';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { TranslateModule } from '@ngx-translate/core';
import { PpTranslatePipe } from './pipes/pp-translate.pipe';

import { 
  MatDatepickerModule, 
  MatNativeDateModule
} from '@angular/material';

@NgModule({
  declarations: [
    ImagePipe,
    PhoneModalComponent,
    PaymentModalComponent,
    PhoneFormComponent,
    FooterComponent,
    TopRightComponent,
    ServicePickModalComponent,
    PlacePickModalComponent,
    DatePickModalComponent,
    HoursPickModalComponent,
    SearchByNameModalComponent,
    PpTranslatePipe,
    SearchByNameModalComponent,
    OfferInfoModalComponent,
    EventCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AngularPhoneUtilsLibModule,
    RouterModule,
    IonIntlTelInputModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    ImagePipe,
    PhoneModalComponent,
    PhoneFormComponent,
    FooterComponent,
    TopRightComponent,
    TranslateModule,
    PpTranslatePipe,
    OfferInfoModalComponent,
    EventCardComponent
  ],
  entryComponents: [
    ServicePickModalComponent,
    PlacePickModalComponent,
    DatePickModalComponent,
    HoursPickModalComponent,
    SearchByNameModalComponent
  ]
})
export class SharedModule { }