import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './core/guards/logged-in.guard';

const routes: Routes = [


  { path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule), pathMatch: 'full' },
  
  { path: 'shambala', redirectTo: 'restavracija/331/restavracija-shambala' },
  { path: 'ruj', redirectTo: 'restavracija/332/gostilnica-ruj' },
  { path: 'putr', redirectTo: 'restavracija/333/restavracija-putr' },
  { path: 'bistro-storja', redirectTo: 'restavracija/334/bistro-storja' },
  { path: 'storja', redirectTo: 'restavracija/334/bistro-storja' },
  { path: 'rozmarin', redirectTo: 'restavracija/335/restavracija-rozmarin' },
  { path: 'grajski-vrt', redirectTo: 'restavracija/370/grajski-vrt' },
  { path: 'pen', redirectTo: 'restavracija/347/pen-klub-restavracija' },
  
  { path: 'strelec', redirectTo: 'restavracija/344/restavracija-strelec' },

  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },

  { path: 'moj-profil', canActivate: [ LoggedInGuard ], loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule) },

  {  
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'izkusnjo',
    loadChildren: () => import('./rating/rating.module').then( m => m.RatingPageModule)
  },
  
  { path: 'restavracija/:providerId/:shopSlug', loadChildren: () => import('./shop-detail/shop-detail.module').then(m => m.ShopDetailPageModule) },

  //shop-listing routes
  //{ path: ':location/:serviceType/:service', loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingPageModule) },
  //{ path: ':location/:serviceType', loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingPageModule) },
  //{ path: ':location', loadChildren: () => import('./shop-listing/shop-listing.module').then(m => m.ShopListingPageModule) },

  //not found routes redirect to landing page

  { path: '**', redirectTo: '' },
  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', enableTracing: false , preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }