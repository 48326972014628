<!--<ion-header>
  <ion-toolbar mode="ios">
    <ion-title >Izberi dan</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>-->

<ion-header class="ion-padding header header-modal -primary" mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios" color="primary">
    <ion-title class="header-title ion-text-left">Ura obiska</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" min="0" max="2400" color="primary">
  <ion-label  class="ion-padding">{{ hours?.value?.lower }}:00 - {{ hours?.value?.upper }}:00</ion-label>

  <ion-range class="clickable" #hours [value]="{ 
    lower: ( ( searchPreferences.hoursPeriod?.fromHour )? searchPreferences.hoursPeriod?.fromHour: 6 ),
    upper: ( ( searchPreferences.hoursPeriod?.toHour )? searchPreferences.hoursPeriod?.toHour: 22 )
    }" dualKnobs="true" min="6" max="22" step="1" snaps="true" class="ion-padding" mode="ios"></ion-range>
</ion-content>

<ion-footer mode="md">
  <ion-toolbar  class="ion-no-padding">
    <ion-button (click)="onConfirmedChange( hours?.value )" color="secondary" expand="full" class="btn--large ion-no-margin">Potrdi</ion-button>
  </ion-toolbar>
</ion-footer>

<!--<h1>od {{ hours.value.lower }}h do {{ hours.value.upper }}h</h1>

<ion-item>
  <ion-range #hours [value]="{ 
    lower: ( ( searchPreferences.hoursPeriod?.fromHour )? searchPreferences.hoursPeriod.fromHour: 6 ),
    upper: ( ( searchPreferences.hoursPeriod?.toHour )? searchPreferences.hoursPeriod.toHour: 22 )
    }" dualKnobs="true" min="6" max="22" step="1" snaps="true"></ion-range>
</ion-item>
 
 <ion-button (click)="onConfirmedChange( hours.value )">Potrdi</ion-button>

</ion-content>-->