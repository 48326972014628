import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PPAuthService } from './core/services/auth.service';
import { UrlService } from './core/services/url.service';
import { TranslateService } from "@ngx-translate/core";

import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  previousUrl: string = null;
  currentUrl: string = null;
  routerEventsSubscription: Subscription;
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: PPAuthService,
    private router: Router,
    private urlService: UrlService,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService
  ) {
    this.initializeApp();
    this.authService.setMe();

    this.authService.me2$.subscribe( (user) => 
      console.log( user )
    )
  }

  ngOnInit() {
      this.routerEventsSubscription = this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
         


         this.previousUrl = this.currentUrl;
         this.urlService.setPreviousUrl( this.currentUrl ); 
         this.currentUrl = event.url;

         //Force language through URL
         let supportedLanguages = ['sl', 'en', 'de', 'it'];

         if ( this.activatedRoute.snapshot.queryParams['lang'] 
           && supportedLanguages.includes( this.activatedRoute.snapshot.queryParams['lang'] ) ) {
           this.translateService.use( this.activatedRoute.snapshot.queryParams['lang'] );
         }

         //GTM 
         //this.gtmService.pushTag( { event: 'page', pageName: event.url } );

      });
  }

  ngOnDestroy() {
      this.routerEventsSubscription.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
