import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-top-right',
  templateUrl: './top-right.component.html',
  styleUrls: ['./top-right.component.scss'],
})
export class TopRightComponent implements OnInit {

  @Input() mode: string;

  constructor( public translateService: TranslateService ) { }

  ngOnInit() {}

  onChange( $event ) {

      this.translateService.use($event.detail.value);

  }

}
