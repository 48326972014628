<ion-buttons>
  
  <!--<ion-button>
    <ion-icon class="profile"></ion-icon>
  </ion-button>-->

  <!--ion-list>
  <ion-item lines="none">
    <ion-icon name="globe-outline"></ion-icon>
    <ion-select style="padding-left:5px; max-width:100%" [(ngModel)]="translateService.currentLang" (ionChange)="translateService.use($event.value)">
      <ion-select-option value="sl">SI</ion-select-option>
      <ion-select-option value="en">EN</ion-select-option>
      <ion-select-option value="de">DE</ion-select-option>
      <ion-select-option value="it">IT</ion-select-option>
      <ion-select-option value="hr">HR</ion-select-option>
    </ion-select>
  </ion-item>
  </ion-list-->

  <ion-select interface="action-sheet" [style.color]="mode==='strelec-landing'?'#ffffff':'#0100ee'" [selectedText]="translateService.currentLang.toUpperCase()" style="padding-left:5px; max-width:100%; color: #0100ee; font-size: small;" [ngModel]="translateService.currentLang" (ionChange)="onChange($event)">
      <ion-select-option value="sl">SL - Slovenščina</ion-select-option>
      <ion-select-option value="en">EN - English</ion-select-option>
      <ion-select-option value="de">DE - Deutsch</ion-select-option>
      <ion-select-option value="it">IT - Italiano</ion-select-option>
      <!--ion-select-option value="hr">HR - Hrvatski</ion-select-option-->
  </ion-select>

  <!--ion-button *ngIf="translateService.currentLang !== 'sl'" class="language-button" (click)="translateService.use('sl');">
    SI
  </ion-button>

  <ion-button *ngIf="translateService.currentLang !== 'en'" class="language-button" (click)="translateService.use('en')">
    EN
  </ion-button-->
        
  <ion-menu-button autoHide="false" [color]="mode==='strelec-landing'?'light':'secondary'" color="secondary"></ion-menu-button>

  <!--<ion-button (click)="logout($event)">Odjava</ion-button>-->

</ion-buttons>