import { Service } from "./service.model"

export class Attendee {

  public name: string;
  public services: Service[];
  public depsIssue: boolean = false;

  constructor(
    obj?: any 
  ) {
    this.name = obj && obj.name || null;
    this.services = obj && obj.services || [];
  }


  getCategoriesPicked(): Array<number> {
    const ids = this.services.map( (value, index, array) => {
      return value.parentId;
    });

    const uniqueIds = [...new Set(ids)];
    return uniqueIds;
  }

  setDepsIssue( hasIssue: boolean ) {
    this.depsIssue = hasIssue;
  }

  areServicesPicked() {
    return ( this.services.length > 0 );
  }

  pickMaxServiceFilter( parentId, pickMax ) {

    if ( !pickMax || pickMax < 1 ) {
      return;
    }

    let categoryIndexArray: Array<number> = [];

    for( let i=0; i < this.services.length; i++ ) {
      
      if ( parentId === this.services[i].parentId ) {
        categoryIndexArray.push( i );
      }

      const diff = categoryIndexArray.length - pickMax

      if ( diff > 0 ) {
        this.services.splice( categoryIndexArray[0], diff )
      }

    }

  }

  toggleService( serviceData, categoryData ) {

    for( let i=0; i < this.services.length; i++ ) {
      if ( this.services[i].id == serviceData.id ) {
        this.services.splice(i, 1);
        return true;
      }
    }

   
    const service = new Service({
      'id': serviceData.id,
      'url': serviceData._links.self.href,
      'name': serviceData.name,
      'duration': serviceData.duration,
      'parentId': categoryData.id
    });

    if ( categoryData.dependencies && categoryData.dependencies.fullfilledBy && categoryData.dependencies.fullfilledBy[0] ) {
      service.parentDependencies = categoryData.dependencies;
    }

    if ( serviceData.dependencies && serviceData.dependencies.fullfilledBy && serviceData.dependencies.fullfilledBy[0] ) {
      service.dependencies = serviceData.dependencies;
    }
    
    this.services.push(service);

    this.pickMaxServiceFilter( categoryData.id, categoryData.childrenPickMax ? categoryData.childrenPickMax: 0 );
    
    return service;

  }

}