import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Pick } from '../../core/models/cart/pick.model';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {


  ccNumber: string = '**** **** **** ****';
  ccName: string = '************';
  ccValid: string = '****';
  ccCvc: string = '***';

  @Input() pick: Pick;

  constructor(private modalCtrl: ModalController ) { }

  ngOnInit() {}

  randomData() {

    this.ccNumber = '0123 2341 4123 3402';
    this.ccName = 'Janez Novak';
    this.ccValid = '12/23';
    this.ccCvc = '191';

  }
 
  onCancel() {
    console.log('sdgsdgsdg');
    this.modalCtrl.dismiss( 'close', 'cancel' );
  }

}
