import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { UnauthInterceptor } from './http-interceptors/unauth-interceptor';
import { MenuComponent} from './menu/menu.component';

import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    MenuComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    FormsModule
  ],
  exports: [
    MenuComponent,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthInterceptor, multi: true },
  ],
})
export class CoreModule { }